import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/portfolio.js";
import { graphql } from "gatsby";
export const query = graphql`
  query {
    nerium1: file(relativePath: { eq: "Nerium/nerium-01.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium2: file(relativePath: { eq: "Nerium/nerium-02.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium3: file(relativePath: { eq: "Nerium/nerium-03.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium4: file(relativePath: { eq: "Nerium/nerium-04.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium5: file(relativePath: { eq: "Nerium/nerium-05.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium6: file(relativePath: { eq: "Nerium/nerium-06.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium7: file(relativePath: { eq: "Nerium/nerium-07.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium8: file(relativePath: { eq: "Nerium/nerium-08.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium9: file(relativePath: { eq: "Nerium/nerium-09.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium10: file(relativePath: { eq: "Nerium/nerium-10.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium11: file(relativePath: { eq: "Nerium/nerium-11.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium12: file(relativePath: { eq: "Nerium/nerium-12.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nerium13: file(relativePath: { eq: "Nerium/nerium-13.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridTwo = makeShortcode("GridTwo");
const ImageContainer = makeShortcode("ImageContainer");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <GridTwo mdxType="GridTwo">
      <ImageContainer classes="large" fluid={props.data.nerium1.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.nerium2.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.nerium3.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="large" fluid={props.data.nerium4.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The new design included language localization. To keep the selections from being to overwhelming for users, I decided to go with a region visualization that once selected, provided options to the languages available in that area.</p>
      <ImageContainer classes="small right" fluid={props.data.nerium5.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.nerium6.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.nerium7.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.nerium12.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.nerium8.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.nerium9.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="large" fluid={props.data.nerium10.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.nerium11.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.nerium13.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small right blog-text">Please contact me if you'd like to learn more about this project.</p>
    </GridTwo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      